import { ReduxInitialState } from "@/types/positions";
import { createSlice } from "@reduxjs/toolkit";

const initialState: ReduxInitialState = {
	tableActions: {
		pagination: {
			page: 1,
			pageSize: 10,
		},
		sort: "asc",
		selection: [],
		filter: {
			status: [],
			offices: [],
		},
	},
};

const positionSlice = createSlice({
	name: "positions",
	initialState,
	reducers: {
		setTableActions: (
			state,
			{ payload }: { payload: Partial<ReduxInitialState["tableActions"]> },
		) => {
			state.tableActions = { ...state.tableActions, ...payload };
		},
	},
});

export default positionSlice.reducer;
export const { setTableActions } = positionSlice.actions;
