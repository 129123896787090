import { TIntegration } from "@/types/mock";
import { createSlice } from "@reduxjs/toolkit";

type IntegrationsState = {
	loading?: null | TIntegration["type"];
};

const initialState: IntegrationsState = {};

const integrations = createSlice({
	name: "integrations",
	initialState,
	reducers: {
		setIntegrationLoading(
			state,
			{ payload }: { payload: TIntegration["type"] | null },
		) {
			state.loading = payload;
		},
	},
});

export default integrations.reducer;
export const { setIntegrationLoading } = integrations.actions;
