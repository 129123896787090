import { ReduxInitialState } from "@/types/people";
import { createSlice } from "@reduxjs/toolkit";

const initialState: ReduxInitialState = {
	positionPanel: {
		people: [],
		pagination: { page: 1, pageSize: 10 },
		sort: "asc",
	},
	groupPanel: {
		sort: "asc",
		people: [],
		pagination: { page: 1, pageSize: 10 },
	},
	group: {
		name: "",
		group_id: "",
		parent_group_id: "",
	},
	role: {
		title: "",
		organization_role_id: "",
		position_id: "",
	},
	tableActions: {
		pagination: {
			page: 1,
			pageSize: 10,
		},
		sort: "asc",
		filter: {
			groups: [],
		},
		selection: [],
	},
	orgChart: {
		intersections: [],
	},
	versionControl: {
		filter: {
			views: [],
		},
	},
};

const peopleSlice = createSlice({
	name: "people",
	initialState,
	reducers: {
		setPositionPanel: (
			state,
			{ payload }: { payload: Partial<ReduxInitialState["positionPanel"]> },
		) => {
			state.positionPanel = { ...state.positionPanel, ...payload };
		},
		setGroupPanel: (
			state,
			{ payload }: { payload: Partial<ReduxInitialState["groupPanel"]> },
		) => {
			state.groupPanel = { ...state.groupPanel, ...payload };
		},
		setAddGroup: (
			state,
			{ payload }: { payload: ReduxInitialState["group"] },
		) => {
			state.group = { ...state.group, ...payload };
		},
		setRole: (
			state,
			{ payload }: { payload: Partial<ReduxInitialState["role"]> },
		) => {
			state.role = { ...state.role, ...payload };
		},
		setTableActions: (
			state,
			{ payload }: { payload: Partial<ReduxInitialState["tableActions"]> },
		) => {
			state.tableActions = { ...state.tableActions, ...payload };
		},
		setOrgChart: (
			state,
			{ payload }: { payload: Partial<ReduxInitialState["orgChart"]> },
		) => {
			state.orgChart = { ...state.orgChart, ...payload };
		},
		setVersionControl: (
			state,
			{ payload }: { payload: Partial<ReduxInitialState["versionControl"]> },
		) => {
			state.versionControl = { ...state.versionControl, ...payload };
		},
	},
});

export default peopleSlice.reducer;
export const {
	setPositionPanel,
	setGroupPanel,
	setAddGroup,
	setRole,
	setTableActions,
	setOrgChart,
	setVersionControl,
} = peopleSlice.actions;
