import { configureStore } from "@reduxjs/toolkit";
import modal from "./generic-slices/modals";
import invitation from "./slices/auth/invitation";
import resetPassword from "./slices/auth/reset-password";
import signIn from "./slices/auth/sign-in";
import signUp from "./slices/auth/sign-up";
import blueprints from "./slices/blueprints";
import copilot from "./slices/copilot";
import integrations from "./slices/integrations";
import objectives from "./slices/objectives";
import people from "./slices/people";
import positions from "./slices/positions";
import recruit from "./slices/recruit";
import roles from "./slices/roles";
import ui from "./slices/ui";
import values from "./slices/values";

const store = configureStore({
	reducer: {
		ui,
		modal,
		roles,
		signUp,
		values,
		signIn,
		copilot,
		people,
		recruit,
		positions,
		objectives,
		blueprints,
		invitation,
		integrations,
		resetPassword,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: ["modalSlice/setGlobalModal"],
				ignoredActionPaths: ["meta.arg", "payload.timestamp"],
				ignoredPaths: [
					"modal.recruit.selected_step.dragHandleProps.onDragStart",
				],
			},
		}),
});

export default store;
export type RootStore = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
