import { TStatus } from "@/types";
import { createSlice } from "@reduxjs/toolkit";

interface Field {
	value: string;
	invalid: boolean;
	invalidText?: string;
	fieldSuccess?: boolean;
}

type status =
	| TStatus
	| "magic-link-loading"
	| "magic-link-error"
	| "duplication-error";

type Signup = {
	email: Field;
	password: Field;
	auth: {
		status?: status;
	};
	accountCreation: {
		status: TStatus | "duplication-error";
	};
	emailVerification: {
		status?: status;
		count?: number;
	};
	profileInformation: {
		orgName?: string;
		firstName?: string;
		lastName?: string;
	};
};

const initialState: Signup = {
	email: {
		value: "",
		invalid: false,
		fieldSuccess: false,
	},
	password: {
		value: "",
		invalid: false,
		fieldSuccess: false,
	},
	accountCreation: {
		status: "initial",
	},
	auth: {
		status: "initial",
	},
	emailVerification: {
		status: "initial",
		count: 0,
	},
	profileInformation: {
		orgName: "",
		firstName: "",
		lastName: "",
	},
};

const signUp = createSlice({
	name: "signUpSlice",
	initialState,
	reducers: {
		setEmail(state, { payload }: { payload: Field }) {
			state.email = payload;
		},
		setPassword(state, { payload }: { payload: Field }) {
			state.password = payload;
		},
		setAuth(state, { payload }: { payload: Signup["auth"] }) {
			state.auth = payload;
		},
		setAccountCreation(
			state,
			{ payload }: { payload: Signup["accountCreation"] },
		) {
			state.accountCreation = payload;
		},
		setEmailVerification(
			state,
			{ payload }: { payload: Signup["emailVerification"] },
		) {
			state.emailVerification = payload;
		},
		setProfileInformation(
			state,
			{ payload }: { payload: Signup["profileInformation"] },
		) {
			state.profileInformation = { ...state.profileInformation, ...payload };
		},
	},
});

export default signUp.reducer;
export const {
	setAuth,
	setEmail,
	setPassword,
	setAccountCreation,
	setEmailVerification,
	setProfileInformation,
} = signUp.actions;
