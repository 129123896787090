import { TStatus } from "@/types";
import { createSlice } from "@reduxjs/toolkit";

interface Field {
	value: string;
	invalid: boolean;
	invalidText?: string;
	fieldSuccess?: boolean;
}

type Invitation = {
	email: Field;
	password: Field;
	auth: {
		status?: TStatus | "magic-link-loading";
	};
	accountCreation: {
		status: TStatus;
	};

	profileInformation: {
		orgName?: string;
		firstName?: string;
		lastName?: string;
	};
};

const initialState: Invitation = {
	email: {
		value: "",
		invalid: false,
		fieldSuccess: false,
	},
	password: {
		value: "",
		invalid: false,
		fieldSuccess: false,
	},
	accountCreation: {
		status: "initial",
	},
	auth: {
		status: "initial",
	},

	profileInformation: {
		orgName: "",
		firstName: "",
		lastName: "",
	},
};

const invitation = createSlice({
	name: "invitationSlice",
	initialState,
	reducers: {
		setEmail(state, { payload }: { payload: Field }) {
			state.email = payload;
		},
		setPassword(state, { payload }: { payload: Field }) {
			state.password = payload;
		},
		setAuth(state, { payload }: { payload: Invitation["auth"] }) {
			state.auth = payload;
		},
		setAccountCreation(
			state,
			{ payload }: { payload: Invitation["accountCreation"] },
		) {
			state.accountCreation = payload;
		},
		setProfileInformation(
			state,
			{ payload }: { payload: Invitation["profileInformation"] },
		) {
			state.profileInformation = { ...state.profileInformation, ...payload };
		},
	},
});

export default invitation.reducer;
export const {
	setAuth,
	setEmail,
	setPassword,
	setAccountCreation,
	setProfileInformation,
} = invitation.actions;
