import { TReduxInitialState } from "@/types/blueprints";
import { createSlice } from "@reduxjs/toolkit";

const initialState: TReduxInitialState = {
	blueprintChart: {
		intersections: [],
		isDragging: false,
	},
	versionControl: {
		filter: {
			views: [],
		},
	},
};

const blueprints = createSlice({
	name: "blueprints",
	initialState,
	reducers: {
		setBlueprintChart: (
			state,
			{ payload }: { payload: Partial<TReduxInitialState["blueprintChart"]> },
		) => {
			state.blueprintChart = { ...state.blueprintChart, ...payload };
		},
		setVersionControl: (
			state,
			{ payload }: { payload: Partial<TReduxInitialState["versionControl"]> },
		) => {
			state.versionControl = { ...state.versionControl, ...payload };
		},
	},
});

export default blueprints.reducer;
export const { setBlueprintChart, setVersionControl } = blueprints.actions;
