import { TStatus } from "@/types";
import { createSlice } from "@reduxjs/toolkit";

interface Field {
	value: string;
	invalid: boolean;
	invalidText?: string;
	fieldSuccess?: boolean;
}

type SignIn = {
	auth: {
		status?: TStatus | "magic-link-loading" | "magic-link-error";
	};
	email: Field;
	password: Field;
	rememberMe?: boolean;
	passwordlessSign: {
		isPasswordless: boolean;
		status?: TStatus;
		count?: number;
	};
};

const initialState: SignIn = {
	email: {
		value: localStorage.getItem("rememberMe") ?? "",
		invalid: false,
	},
	password: {
		value: "",
		invalid: false,
	},
	auth: {
		status: "initial",
	},
	passwordlessSign: {
		status: "initial",
		isPasswordless: false,
	},
	rememberMe: Boolean(localStorage.getItem("rememberMe")),
};

const signIn = createSlice({
	name: "signInSlice",
	initialState,
	reducers: {
		setEmail(state, { payload }: { payload: Field }) {
			state.email = payload;
		},
		setPassword(state, { payload }: { payload: Field }) {
			state.password = payload;
		},
		setAuth(state, { payload }: { payload: SignIn["auth"] }) {
			state.auth = payload;
		},
		setPasswordlessSign(
			state,
			{ payload }: { payload: SignIn["passwordlessSign"] },
		) {
			state.passwordlessSign = payload;
		},
		setRememberMe(state) {
			state.rememberMe = !state.rememberMe;
		},
	},
});

export default signIn.reducer;
export const {
	setAuth,
	setEmail,
	setPassword,
	setRememberMe,
	setPasswordlessSign,
} = signIn.actions;
