import { TCopilot } from "@/types/copilot";
import { TAgent } from "@/types/services";
import { ai_models, ai_providers, voice_models } from "@/utils/mock/copilot";
import { createSlice } from "@reduxjs/toolkit";

type InitialState = {
	update: TCopilot;
	selected_agents: TAgent[];
	pagination: {
		page: number;
		pageSize: number;
	};
};

export const initial_copilot: TCopilot = {
	name: "",
	description: "",
	personality_prompt: "",
	safety_system: {
		avoid_harmful_content: false,
		avoid_ungrounded_content: false,
		avoid_copyright: false,
		avoid_jailbreaks: false,
	},
	style_alignment: 50,
	provider: ai_providers[0],
	model: ai_models[0],
	voice: voice_models[0],
	back_channeling: false,
	detect_emotion: false,
	speed: 50,
	temperature: 0.5,
	max_tokens: 500,
	top_p: 0.5,
	frequency_penalty: 0,
	presence_penalty: 0,
};

const initialState: InitialState = {
	update: { ...initial_copilot, name: "Manage AI Copilot" },
	selected_agents: [],
	pagination: {
		page: 1,
		pageSize: 10,
	},
};

export const copilot = createSlice({
	name: "copilot",
	initialState,
	reducers: {
		setCopilotSlice: (
			state,
			{ payload }: { payload: Partial<InitialState> },
		) => {
			return { ...state, ...payload };
		},
		setUpdate: (state, { payload }: { payload: TCopilot }) => {
			state.update = payload;
		},
		setSelectedAgents: (state, { payload }: { payload: TAgent[] }) => {
			state.selected_agents = payload;
		},
	},
});

export const { setUpdate, setSelectedAgents, setCopilotSlice } =
	copilot.actions;
export default copilot.reducer;
