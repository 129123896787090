import { AIModel, AIProvider, AIVoice } from "@/types/copilot";

export const voice_models: AIVoice[] = [
	{ id: "Ava", label: "Ava" },
	{ id: "Andrew", label: "Andrew" },
	{ id: "Emma", label: "Emma" },
	{ id: "Brian", label: "Brian" },
	{ id: "Jenny", label: "Jenny" },
	{ id: "Guy", label: "Guy" },
	{ id: "Aria", label: "Aria" },
	{ id: "Davis", label: "Davis" },
	{ id: "Jane", label: "Jane" },
	{ id: "Jason", label: "Jason" },
	{ id: "Sara", label: "Sara" },
	{ id: "Tony", label: "Tony" },
	{ id: "Nancy", label: "Nancy" },
	{ id: "Amber", label: "Amber" },
	{ id: "Ana", label: "Ana" },
	{ id: "Ashley", label: "Ashley" },
	{ id: "Brandon", label: "Brandon" },
	{ id: "Christopher", label: "Christopher" },
	{ id: "Cora", label: "Cora" },
	{ id: "Elizabeth", label: "Elizabeth" },
	{ id: "Eric", label: "Eric" },
	{ id: "Jacob", label: "Jacob" },
	{ id: "JennyMultilingual", label: "Jenny Multilingual" },
	{ id: "Michelle", label: "Michelle" },
	{ id: "Monica", label: "Monica" },
	{ id: "Roger", label: "Roger" },
	{ id: "RyanMultilingual", label: "Ryan Multilingual" },
	{ id: "Steffan", label: "Steffan" },
	{ id: "AIGenerate1", label: "AI Generate 1" },
	{ id: "AIGenerate2", label: "AI Generate 2" },
	{ id: "AndrewMultilingual", label: "Andrew Multilingual" },
	{ id: "AvaMultilingual", label: "Ava Multilingual" },
	{ id: "Blue", label: "Blue" },
	{ id: "Kai", label: "Kai" },
	{ id: "Luna", label: "Luna" },
	{ id: "BrianMultilingual", label: "Brian Multilingual" },
	{ id: "EmmaMultilingual", label: "Emma Multilingual" },
	{ id: "AlloyMultilingual", label: "Alloy Multilingual" },
	{ id: "EchoMultilingual", label: "Echo Multilingual" },
	{ id: "FableMultilingual", label: "Fable Multilingual" },
	{ id: "OnyxMultilingual", label: "Onyx Multilingual" },
	{ id: "Nova", label: "Nova" },
	{ id: "Shimmer", label: "Shimmer" },
];

export const ai_providers: AIProvider[] = [{ id: "openai", label: "OpenAI" }];

export const ai_models: AIModel[] = [{ id: "gpt-4o", label: "GPT 4o Cluster" }];
