import { InitialState } from "@/types/objectives";
import { filter_options } from "@/utils/mock/objectives";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: InitialState = {
	table: {
		pagination: {
			pageSize: 10,
			page: 1,
		},
		sort: "asc",
	},
	filter: filter_options,
};

const objectives = createSlice({
	name: "objectives",
	initialState,
	reducers: {
		setTable: (
			state,
			{ payload }: PayloadAction<Partial<InitialState["table"]>>,
		) => {
			state.table = { ...state.table, ...payload };
		},
		setFilter: (state, { payload }: PayloadAction<any>) => {
			state.filter = payload;
		},
	},
});

export default objectives.reducer;
export const { setTable, setFilter } = objectives.actions;
