import { FilterForm, objective_alignment } from "@/types/objectives";
import { GaugeChartOptions, RadarChartOptions } from "@carbon/charts-react";
import { Database } from "database.types";

export const objective_type: Array<{
	label: string;
	value: Database["public"]["Enums"]["objective_type"];
}> = [
	{
		label: "Personal",
		value: "PERSONAL",
	},
	{
		label: "Group",
		value: "GROUP",
	},
	{
		label: "Company",
		value: "COMPANY",
	},
];

export const group_type: Array<{
	label: string;
	value: Database["public"]["Enums"]["groups_type"];
}> = [
	{
		label: "Department",
		value: "DEPARTMENT",
	},
	{
		label: "Team",
		value: "TEAM",
	},
];

export const align_parent_progress: Array<{ label: string; value: boolean }> = [
	{
		value: true,
		label: "Impact parent objective's progress.",
	},
	{
		value: false,
		label: "Do not impact parent objective's progress.",
	},
];

export const objective_access: Array<{ label: string; value: boolean }> = [
	{
		label: "Private",
		value: true,
	},
	{
		label: "Public",
		value: false,
	},
];

export const time_period: Array<{ label: string; value: string }> = [
	// Previous year
	{
		label: `Q1-${new Date().getFullYear() - 1}`,
		value: `Q1-${new Date().getFullYear() - 1}`,
	},
	{
		label: `Q2-${new Date().getFullYear() - 1}`,
		value: `Q2-${new Date().getFullYear() - 1}`,
	},
	{
		label: `Q3-${new Date().getFullYear() - 1}`,
		value: `Q3-${new Date().getFullYear() - 1}`,
	},
	{
		label: `Q4-${new Date().getFullYear() - 1}`,
		value: `Q4-${new Date().getFullYear() - 1}`,
	},
	// Current Year
	{
		label: `Q1-${new Date().getFullYear()}`,
		value: `Q1-${new Date().getFullYear()}`,
	},
	{
		label: `Q2-${new Date().getFullYear()}`,
		value: `Q2-${new Date().getFullYear()}`,
	},
	{
		label: `Q3-${new Date().getFullYear()}`,
		value: `Q3-${new Date().getFullYear()}`,
	},
	{
		label: `Q4-${new Date().getFullYear()}`,
		value: `Q4-${new Date().getFullYear()}`,
	},
	// Next Year
	{
		label: `Q1-${new Date().getFullYear() + 1}`,
		value: `Q1-${new Date().getFullYear() + 1}`,
	},
	{
		label: `Q2-${new Date().getFullYear() + 1}`,
		value: `Q2-${new Date().getFullYear() + 1}`,
	},
	{
		label: `Q3-${new Date().getFullYear() + 1}`,
		value: `Q3-${new Date().getFullYear() + 1}`,
	},
	{
		label: `Q4-${new Date().getFullYear() + 1}`,
		value: `Q4-${new Date().getFullYear() + 1}`,
	},
];

export const key_results_type: Array<{
	label: string;
	value: Database["public"]["Enums"]["key_results_type"];
}> = [
	{
		label: "Completion",
		value: "COMPLETION",
	},
	{
		label: "Currency",
		value: "CURRENCY",
	},
	{
		label: "Number",
		value: "NUMBER",
	},
	{
		label: "Percentage",
		value: "PERCENTAGE",
	},
];

export const key_result_completion_options: Array<{
	label: string;
	value: "COMPLETED" | "NOT_COMPLETED";
}> = [
	{
		label: "Completed",
		value: "COMPLETED",
	},
	{
		label: "Not Completed",
		value: "NOT_COMPLETED",
	},
];

export const quarter_time_period: Array<{
	label: string;
	value: "Q1" | "Q2" | "Q3" | "Q4";
}> = [
	{
		label: "March 31",
		value: "Q1",
	},
	{
		label: "June 3",
		value: "Q2",
	},
	{
		label: "September 30",
		value: "Q3",
	},
	{
		label: "December 31",
		value: "Q4",
	},
];

export const tab_options: Array<{
	selectedIndex: number;
	key: "my-own" | "my-team" | "company" | "departments" | "teams" | "all";
}> = [
	{
		selectedIndex: 0,
		key: "my-own",
	},
	{
		selectedIndex: 1,
		key: "my-team",
	},
	{
		selectedIndex: 2,
		key: "company",
	},
	{
		selectedIndex: 3,
		key: "departments",
	},
	{
		selectedIndex: 4,
		key: "teams",
	},
	{
		selectedIndex: 5,
		key: "all",
	},
];

export const objective_status: Array<{
	label: string;
	value: Database["public"]["Enums"]["objective_status"];
}> = [
	{
		label: "At Risk",
		value: "AT_RISK",
	},
	{
		label: "On Track",
		value: "ON_TRACK",
	},
	{
		label: "Behind",
		value: "BEHIND",
	},
	{
		label: "Complete",
		value: "COMPLETE",
	},
];

export const object_alignment: Array<{
	label: string;
	value: objective_alignment;
}> = [
	{
		label: "Parent Aligned",
		value: "parentAligned",
	},
	{
		label: "Child, not Aligned",
		value: "childUnaligned",
	},
	{
		label: "Child, Aligned",
		value: "childAligned",
	},
];

export const objective_state: Array<{
	label: string;
	value: Database["public"]["Enums"]["objective_state"];
}> = [
	{
		label: "Active",
		value: "ACTIVE",
	},
	{
		label: "Archived",
		value: "ARCHIVED",
	},
	{
		label: "Closed",
		value: "CLOSED",
	},
];

export const filter_options: FilterForm = {
	objective_scope: [],
	objective_status: [],
	objective_alignment: [],
	objective_creator: [],
	objective_owner: [],
	key_result_owner: [],
	group_type: [],
	objective_group: [],
	start_date: "",
	end_date: "",
	objective_state: [],
	objective_privacy: [],
};

export const growth_opportunity_options: GaugeChartOptions = {
	toolbar: {
		enabled: false,
	},
	resizable: true,
	height: "135px",
	gauge: {
		type: "semi",
		status: "danger",
	},
	theme: "g10",
};

export const objective_profile_options: RadarChartOptions = {
	title: "",
	toolbar: {
		enabled: false,
	},
	radar: {
		axes: {
			angle: "feature",
			value: "score",
		},
	},
	data: {
		groupMapsTo: "group",
	},
	height: "250px",
};
