import { TVersion } from "@/components/people/customs/org-chart/sidepanel/version-control/features";
import { TVersion as TBlueprintVersion } from "@/types/blueprints";
import { TBlueprint } from "@/types/blueprints";
import { KeyResult, Objective } from "@/types/objectives";
import { PathwayObjective } from "@/types/pathways";
import { Person } from "@/types/people";
import { type Position } from "@/types/positions";
import { TStep } from "@/types/recruit";
import { TRole, TVersion as TRoleVersion } from "@/types/roles";
import { TValue } from "@/types/values";
import { createSlice } from "@reduxjs/toolkit";
import { Database } from "database.types";

export interface IPeople {
	userProfile: boolean;
	versionControl: boolean;
	addPeople: boolean;
	newMember: boolean;
	importMember: boolean;
	removeMember: boolean;
	manageMember: boolean;
	cancelInvitation: { open: boolean; props?: { email: string; id: string } };
	manageProfile: boolean;
	add_person: boolean;
	add_version: boolean;
	user_profile: boolean;
	add_group: boolean;
	edit_person: { open: boolean; props?: Person | null };
	edit_version: {
		open: boolean;
		props?: TVersion | null;
	};
}

export interface IValues {
	deleteValue: boolean;
	createValue: boolean;
	editValue: { open: boolean; props: TValue | null };
}

export interface ICheckIns {
	talkingAssistant: boolean;
}
export interface IPrompt {
	publishPrompt: boolean;
}

export interface IPost {
	publishPost: boolean;
	promptContent: { show: boolean; props: string };
}

export interface IWorkflow {
	member: { show: boolean; props: any };
}

export interface IRecruit {
	share: boolean;
	createProperties: boolean;
	update_modal: boolean;
	interviewConfig: boolean;
	actions: boolean;
	deleteRole: boolean;
	clear: boolean;
	delay: boolean;
	decide: boolean;
	selected_step: TStep | null;
	unPublish: boolean;
	reviewCandidate: boolean;
	reset_to_default: boolean;
}

// export interface IRole {
// 	apply: boolean;
// }

export interface IUserProfile {
	manageProfile: boolean;
	removeMember: boolean;
}

export interface IPositions {
	add_position: boolean;
	edit_position: {
		open: boolean;
		props?: Position | null;
	};
}

export interface IRoles {
	add_role: boolean;
	add_office: boolean;
	updateRole: {
		open: boolean;
		props?: TRole | null;
	};
	addSkill: boolean;
	addCapability: boolean;
	strategyDevelopment: {
		open: boolean;
		props?:
			| Database["public"]["Tables"]["organization_role_emerging_skills"]["Row"]
			| null;
	};
	editVersion: { open: boolean; props: TRoleVersion | null };
	versionControl: boolean;
	addVersion: boolean;
	inviteManager: boolean;
	restoreVersion: boolean;
}

export interface IBluePrint {
	addBlueprint: boolean;
	updateBlueprint: { open: boolean; props: TBlueprint | null };
	cloneBlueprint: { open: boolean; props: TBlueprint | null };
	inviteManager: boolean;
	removeBlueprint: { open: boolean; props?: string };
	createRole: {
		open: boolean;
		props?: {
			node_position: number;
			type: Database["public"]["Tables"]["blueprint_roles"]["Row"]["type"];
		} | null;
	};
	removeRole: boolean;
	publish: boolean;
	removeVersion: boolean;
	restoreVersion: boolean;
	versionControl: boolean;
	add_version: boolean;
	edit_version: {
		open: boolean;
		props?: TBlueprintVersion | null;
	};
}

export type Objectives = {
	filter: boolean;
	addObjective: boolean;
	editObjective: {
		open: boolean;
		props?: Objective | null;
	};
	closeObjective: { open: boolean; props?: Objective | null };
	addKeyResult: { open: boolean; props?: Objective | null };
	editKeyResult: { open: boolean; props?: KeyResult | null };
	activity: boolean;
	convertToChildObjective: {
		open: boolean;
		props: { objective: Objective; key_result: KeyResult } | null;
	};
	addChildObjective: {
		open: boolean;
		props: Objective | null;
	};
	updateKeyResult: {
		open: boolean;
		props: KeyResult | null;
	};
};

export type Pathways = {
	addObjective: boolean;
	defineWeight: {
		open: boolean;
		props: PathwayObjective | null;
	};
};

export type Organization = {
	createOrganization: boolean;
};

type Modal = {
	post: IPost;
	prompt: IPrompt;
	values: IValues;
	checkIns: ICheckIns;
	themeModal: boolean;
	workflow: IWorkflow;
	recruit: IRecruit;
	user_profile: IUserProfile;
	positions: IPositions;
	roles: IRoles;
	people: IPeople;
	blueprint: IBluePrint;
	objectives: Objectives;
	pathways: Pathways;
	organization: Organization;
};

const initialState: Modal = {
	themeModal: false,
	people: {
		add_person: false,
		versionControl: false,
		userProfile: false,
		addPeople: false,
		newMember: false,
		importMember: false,
		removeMember: false,
		cancelInvitation: { open: false },
		manageMember: false,
		manageProfile: false,
		add_version: false,
		add_group: false,
		user_profile: false,
		edit_person: { open: false, props: null },
		edit_version: {
			open: false,
			props: null,
		},
	},
	user_profile: {
		manageProfile: false,
		removeMember: false,
	},
	values: {
		createValue: false,
		deleteValue: false,
		editValue: { open: false, props: null },
	},
	checkIns: {
		talkingAssistant: false,
	},
	prompt: {
		publishPrompt: false,
	},
	post: {
		publishPost: false,
		promptContent: { show: false, props: "" },
	},
	workflow: {
		member: { show: false, props: {} },
	},
	recruit: {
		share: false,
		createProperties: false,
		update_modal: false,
		interviewConfig: false,
		actions: false,
		deleteRole: false,
		clear: false,
		delay: false,
		decide: false,
		selected_step: null,
		unPublish: false,
		reviewCandidate: false,
		reset_to_default: false,
	},
	roles: {
		addCapability: false,
		addSkill: false,
		strategyDevelopment: { open: false, props: null },
		versionControl: false,
		addVersion: false,
		editVersion: { open: false, props: null },
		inviteManager: false,
		restoreVersion: false,
		add_role: false,
		add_office: false,
		updateRole: { open: false, props: null },
	},
	positions: {
		add_position: false,
		edit_position: { open: false, props: null },
	},
	blueprint: {
		addBlueprint: false,
		updateBlueprint: { open: false, props: null },
		cloneBlueprint: { open: false, props: null },
		inviteManager: false,
		removeBlueprint: { open: false, props: "" },
		createRole: { open: false, props: null },
		removeRole: false,
		publish: false,
		removeVersion: false,
		restoreVersion: false,
		versionControl: false,
		add_version: false,
		edit_version: {
			open: false,
			props: null,
		},
	},
	objectives: {
		filter: false,
		addObjective: false,
		addKeyResult: {
			open: false,
			props: null,
		},
		editObjective: {
			open: false,
			props: null,
		},
		editKeyResult: {
			open: false,
			props: null,
		},
		closeObjective: { open: false, props: null },
		activity: false,
		convertToChildObjective: { open: false, props: null },
		addChildObjective: { open: false, props: null },
		updateKeyResult: { open: false, props: null },
	},
	pathways: {
		addObjective: false,
		defineWeight: { open: false, props: null },
	},
	organization: {
		createOrganization: false,
	},
};
const modal = createSlice({
	name: "modalSlice",
	initialState,
	reducers: {
		setGlobalModal: (state, { payload }: { payload: Partial<Modal> }) => {
			return { ...state, ...(payload as Modal) };
		},
	},
});

export default modal.reducer;
export const { setGlobalModal } = modal.actions;
