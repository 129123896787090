import { ReduxInitialState, TTab } from "@/types/roles";
import { createSlice } from "@reduxjs/toolkit";

const initialState: ReduxInitialState = {
	tableActions: {
		pagination: {
			page: 1,
			pageSize: 10,
		},
		selection: [],
		sort: "asc",
		filter: {
			sort: [],
		},
	},
	roleDefinition: {
		description: "",
		responsibilities: "",
		isGenerating: false,
	},
	skillAndCapabilityList: {
		data: {
			skills: [],
			capabilities: [],
		},
		isGenerating: false,
	},
	emergingSkills: {
		data: {
			skills: [],
			capabilities: [],
		},
		isGenerating: false,
	},
	tabs: JSON.parse(localStorage.getItem("tabs/role") ?? "[]") as TTab[],
	versionControl: {
		filter: {
			views: [],
		},
	},
};

const roleSlice = createSlice({
	name: "roles",
	initialState,
	reducers: {
		setTableActions: (
			state,
			{ payload }: { payload: Partial<ReduxInitialState["tableActions"]> },
		) => {
			state.tableActions = { ...state.tableActions, ...payload };
		},
		setRoleDefinition: (
			state,
			{ payload }: { payload: Partial<ReduxInitialState["roleDefinition"]> },
		) => {
			state.roleDefinition = { ...state.roleDefinition, ...payload };
		},
		setSkillAndCapabilityList: (
			state,
			{
				payload,
			}: { payload: Partial<ReduxInitialState["skillAndCapabilityList"]> },
		) => {
			state.skillAndCapabilityList = {
				...state.skillAndCapabilityList,
				...payload,
			};
		},
		setTabs: (state, { payload }: { payload: ReduxInitialState["tabs"] }) => {
			state.tabs = payload;
		},
		setVersionControl: (
			state,
			{ payload }: { payload: Partial<ReduxInitialState["versionControl"]> },
		) => {
			state.versionControl = { ...state.versionControl, ...payload };
		},
		setEmergingSkills: (
			state,
			{ payload }: { payload: Partial<ReduxInitialState["emergingSkills"]> },
		) => {
			state.emergingSkills = {
				...state.emergingSkills,
				...payload,
			};
		},
	},
});

export default roleSlice.reducer;
export const {
	setTableActions,
	setRoleDefinition,
	setSkillAndCapabilityList,
	setTabs,
	setVersionControl,
	setEmergingSkills,
} = roleSlice.actions;
