import { IRoleData, TReduxInitialState, TTab } from "@/types/recruit";
import { createSlice } from "@reduxjs/toolkit";

export const role_initial = {
	title: "",
	role_type: "full_time",
	manager_id: "",
	role_location: "on_site",
	location: "",
};

const initialState: TReduxInitialState = {
	create: role_initial,
	update: null,
	search_filter: {
		role_type: [],
		role_location: [],
		source: [],
		status: [],
		action: [],
	},
	canban: {
		isDragging: false,
	},
	canban_column: {
		isDragging: null,
		edit: null,
	},
	description: {
		description: "",
		isGenerating: false,
		isCalculating: false,
		isBlocked: false,
	},
	act_interview: {
		interview_topic: "",
		interviewers: [],
		interview_type: null,
	},
	act_move_to_stage: null,
	act_request_approval: {
		body: "",
		reviewers: [],
	},
	act_send_notification: [],
	decide_modal: {
		isDragging: false,
	},
	act_ai_interview: {
		questions: [],
		interpretations: [],
	},
	ai_interview_modal: { isDragging: false },
	decide_step: {
		statements: null,
		conditions: null,
	},
	send_notification_modal: {
		isDragging: false,
	},
	tabs: JSON.parse(localStorage.getItem("tabs/recruit") ?? "[]") as TTab[],
	candidates_drag: {
		isDragging: false,
	},
};

const roles = createSlice({
	name: "roles",
	initialState,
	reducers: {
		setCreate: (
			state,
			{ payload }: { payload: Partial<TReduxInitialState["create"]> },
		) => {
			state.create = { ...state.create, ...payload };
		},
		setUpdate: (state, { payload }: { payload: Partial<IRoleData> }) => {
			state.update = payload;
		},
		setSearchFilter: (
			state,
			{ payload }: { payload: Partial<TReduxInitialState["search_filter"]> },
		) => {
			state.search_filter = { ...state.search_filter, ...payload };
		},
		setCanban: (
			state,
			{ payload }: { payload: Partial<TReduxInitialState["canban"]> },
		) => {
			state.canban = { ...state.canban, ...payload };
		},
		setCanbanColumn: (
			state,
			{ payload }: { payload: Partial<TReduxInitialState["canban_column"]> },
		) => {
			state.canban_column = { ...state.canban_column, ...payload };
		},
		setDescription: (
			state,
			{ payload }: { payload: Partial<TReduxInitialState["description"]> },
		) => {
			state.description = { ...state.description, ...payload };
		},
		setActInterview: (
			state,
			{ payload }: { payload: Partial<TReduxInitialState["act_interview"]> },
		) => {
			state.act_interview = { ...state.act_interview, ...payload };
		},
		setActMoveToStage: (
			state,
			{ payload }: { payload: TReduxInitialState["act_move_to_stage"] },
		) => {
			state.act_move_to_stage = payload;
		},
		setActRequestApproval: (
			state,
			{
				payload,
			}: { payload: Partial<TReduxInitialState["act_request_approval"]> },
		) => {
			state.act_request_approval = {
				...state.act_request_approval,
				...payload,
			};
		},
		setActSendNotification: (
			state,
			{ payload }: { payload: TReduxInitialState["act_send_notification"] },
		) => {
			state.act_send_notification = payload;
		},
		setDecideModal: (
			state,
			{ payload }: { payload: Partial<TReduxInitialState["decide_modal"]> },
		) => {
			state.decide_modal = { ...state.decide_modal, ...payload };
		},
		setActAIInterview: (
			state,
			{ payload }: { payload: Partial<TReduxInitialState["act_ai_interview"]> },
		) => {
			state.act_ai_interview = { ...state.act_ai_interview, ...payload };
		},
		setDecideStep: (
			state,
			{ payload }: { payload: Partial<TReduxInitialState["decide_step"]> },
		) => {
			state.decide_step = { ...state.decide_step, ...payload };
		},
		setSendNotificationModal: (
			state,
			{
				payload,
			}: { payload: Partial<TReduxInitialState["send_notification_modal"]> },
		) => {
			state.send_notification_modal = {
				...state.send_notification_modal,
				...payload,
			};
		},
		setAIInterviewModal: (
			state,
			{
				payload,
			}: { payload: Partial<TReduxInitialState["ai_interview_modal"]> },
		) => {
			state.ai_interview_modal = { ...state.ai_interview_modal, ...payload };
		},
		setTabs: (state, { payload }: { payload: TReduxInitialState["tabs"] }) => {
			state.tabs = payload;
		},
		setCandidatesDrag: (
			state,
			{ payload }: { payload: Partial<TReduxInitialState["candidates_drag"]> },
		) => {
			state.candidates_drag = { ...state.candidates_drag, ...payload };
		},
	},
});

export default roles.reducer;
export const {
	setTabs,
	setUpdate,
	setCreate,
	setSearchFilter,
	setCanban,
	setCanbanColumn,
	setDescription,
	setActInterview,
	setActMoveToStage,
	setActRequestApproval,
	setActSendNotification,
	setDecideModal,
	setActAIInterview,
	setDecideStep,
	setSendNotificationModal,
	setAIInterviewModal,
	setCandidatesDrag,
} = roles.actions;
