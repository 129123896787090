const InitialLoader = () => {
	return (
		<div className="flex flex-col items-center justify-center w-full h-screen">
			<img className="w-10 h-10 animate-spin" src="/logo.svg" alt="logo" />
			<h3 className="mt-3 text-xl">Ebrai Amplify</h3>
		</div>
	);
};

export default InitialLoader;
