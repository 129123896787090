import store from "@/redux";
import { ClerkLoaded, ClerkLoading, ClerkProvider } from "@clerk/clerk-react";
import { dark } from "@clerk/themes";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { FC, ReactNode, Suspense, lazy, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { ThemeConfig } from "./configs/theme.tsx";
import InitialLoader from "./generics/initial-loader/index.tsx";
import { ErrorBoundary } from "./utils/error-boundary";
import "@carbon/charts-react/styles.css";
import "@xyflow/react/dist/style.css";
import "../app/globals.css";
import { useTheme } from "next-themes";

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY)
	throw new Error("Add your Clerk publishable key to the .env.local file");

const App = lazy(() => import("./App.tsx"));

Sentry.init({
	dsn: "https://984dec4e0fa8b41c5248e777a35fd16f@o4505986351300608.ingest.us.sentry.io/4507890279383040",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.browserProfilingIntegration(),
		Sentry.replayIntegration(),
	],
	release: "react-sentry-github@1.0.0",
	tracesSampleRate: 1.0,
	tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
	profilesSampleRate: 1.0,
	replaysSessionSampleRate: 0.1,
});

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			retry: false,
			staleTime: 30000,
		},
		mutations: {
			retry: false,
		},
	},
});

const ClerkWithRouter: FC<{ children: ReactNode }> = ({ children }) => {
	const navigate = useNavigate();
	const { resolvedTheme } = useTheme();

	useEffect(() => {
		const handleRouterPush = (to: string) => navigate(to);
		const handleRouterReplace = (to: string) => navigate(to, { replace: true });

		window.ClerkRouterPush = handleRouterPush;
		window.ClerkRouterReplace = handleRouterReplace;
	}, []);

	return (
		<ClerkProvider
			routerPush={(to) => window.ClerkRouterPush?.(to)}
			routerReplace={(to) => window.ClerkRouterReplace?.(to)}
			publishableKey={PUBLISHABLE_KEY}
			signInUrl={import.meta.env.VITE_CLERK_SIGN_IN_URL}
			signUpUrl={import.meta.env.VITE_CLERK_SIGN_UP_URL}
			appearance={{ baseTheme: resolvedTheme === "dark" ? dark : undefined }}
			afterSignOutUrl={import.meta.env.VITE_CLERK_AFTER_SIGN_OUT_URL}
			signUpForceRedirectUrl={
				import.meta.env.VITE_CLERK_SIGN_UP_FORCE_REDIRECT_URL
			}
			signInForceRedirectUrl={
				import.meta.env.VITE_CLERK_SIGN_IN_FORCE_REDIRECT_URL
			}
		>
			<ClerkLoading>
				<InitialLoader />
			</ClerkLoading>
			<ClerkLoaded>{children}</ClerkLoaded>
		</ClerkProvider>
	);
};

export const GlobalWrapper: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	return (
		<Suspense fallback={<InitialLoader />}>
			<HelmetProvider>
				<BrowserRouter>
					<ThemeConfig>
						<ClerkWithRouter>
							<Provider store={store}>
								<QueryClientProvider client={queryClient}>
									<ReactQueryDevtools />
									{children}
								</QueryClientProvider>
							</Provider>
						</ClerkWithRouter>
					</ThemeConfig>
				</BrowserRouter>
			</HelmetProvider>
		</Suspense>
	);
};

ReactDOM.createRoot(document.getElementById("root")!).render(
	<GlobalWrapper>
		<ErrorBoundary>
			<App />
		</ErrorBoundary>
	</GlobalWrapper>,
);
