import { TStatus } from "@/types";
import { createSlice } from "@reduxjs/toolkit";

type PasswordReset = {
	password: {
		value: string;
		invalid?: boolean;
		invalidText?: string;
		fieldSuccess?: boolean;
	};
	confirmPassword: {
		value: string;
		invalid?: boolean;
		invalidText?: string;
		fieldSuccess?: boolean;
	};
	auth: {
		status:
			| TStatus
			| "magic-link-loading"
			| "magic-link-success"
			| "magic-link-error";
	};
};

const initialState: PasswordReset = {
	auth: {
		status: "initial",
	},
	password: {
		value: "",
	},
	confirmPassword: {
		value: "",
	},
};

const resetPassword = createSlice({
	name: "resetPasswordSlice",
	initialState,
	reducers: {
		setPassword(state, { payload }: { payload: PasswordReset["password"] }) {
			state.password = payload;
		},
		setConfirmPassword(
			state,
			{ payload }: { payload: PasswordReset["confirmPassword"] },
		) {
			state.confirmPassword = payload;
		},
		setAuth(state, { payload }: { payload: PasswordReset["auth"] }) {
			state.auth = payload;
		},
	},
});

export default resetPassword.reducer;
export const { setPassword, setConfirmPassword, setAuth } =
	resetPassword.actions;
