import type { TActionable, TNotification } from "@/types";
import { createSlice } from "@reduxjs/toolkit";
import { WritableDraft } from "immer";

type theme = "dark" | "light" | "system";

interface Sidenav {
	isFixed?: boolean;
	isExpanded: boolean;
}

type InitialState = {
	theme: theme;
	panel: "user-panel" | "notification" | null;
	sidenav: Sidenav;
	notification: {
		stack: Array<TNotification & { id: string }>;
		actionable: Array<TActionable & { id: string }>;
	};
};

const isFixed =
	String(
		localStorage.getItem("sidenav(fixed)") ?? "false",
	).toLocaleLowerCase() === "true";

const initialState: InitialState = {
	theme: (localStorage.getItem("theme") as theme) ?? "light",
	panel: null,
	sidenav: {
		isExpanded: isFixed,
		isFixed: isFixed,
	},
	notification: {
		stack: [],
		actionable: [],
	},
};

const ui = createSlice({
	name: "ui-slice",
	initialState,
	reducers: {
		setSideNav(state, { payload }: { payload: Sidenav }) {
			state.sidenav = payload;
		},
		setTheme(state, { payload }: { payload: theme | undefined }) {
			localStorage.setItem("theme", payload ?? "light");
			state.theme = payload ?? "light";
		},
		setPanel(state, { payload }: { payload: Partial<InitialState["panel"]> }) {
			state.panel = payload;
		},
		setNotification(
			state,
			{ payload }: { payload: Partial<InitialState["notification"]> },
		) {
			state.notification = {
				...state.notification,
				...payload,
			} as WritableDraft<InitialState["notification"]>;
		},
	},
});

export default ui.reducer;
export const { setSideNav, setTheme, setNotification, setPanel } = ui.actions;
