import { TReduxInitialState, TValue } from "@/types/values";
import { createSlice } from "@reduxjs/toolkit";

const initialState: TReduxInitialState = {
	create: {
		title: "",
		description: "",
	},
	update: null,
};

const values = createSlice({
	name: "values",
	initialState,
	reducers: {
		setCreate: (
			state,
			{ payload }: { payload: Partial<TReduxInitialState["create"]> },
		) => {
			state.create = { ...state.create, ...payload };
		},
		setUpdate: (state, { payload }: { payload: TValue }) => {
			state.update = payload;
		},
	},
});

export default values.reducer;
export const { setCreate, setUpdate } = values.actions;
